/*
 * Sets up geolocation on directory pages and redirects to the search page
 */

import { SpinnerModal } from 'js/components/SpinnerModal/SpinnerModal.js';
import { HTML5Geolocation } from 'js/components/Util/Html5Geolocation.js';
HTML5Geolocation.initClass();

export const SetUpGeolocation = async () => {
  const searchForm = document.querySelector('#search-form');
  const geolocateButton = searchForm.querySelector('.js-geolocate');
  const spinner = new SpinnerModal();

  if (geolocateButton) {
    geolocateButton.addEventListener('click', () => {
      spinner.showSpinner();
      HTML5Geolocation.getCurrentLocation(async (position) => {
        console.log('Getting current position');
        if (position.hasOwnProperty('latitude') && position.hasOwnProperty('longitude')) {
          submitSearch(searchForm, position);
        } else {
          console.log("HTML5 Geolocation: Error no position found.");
        }
      }, () => {
        spinner.hideSpinner();
        console.log("HTML5 Geolocation: Error no position found.");
      });
    });
  }
};

/*
 * ==============================================================================
 * ============================== HELPER FUNCTIONS ==============================
 * ==============================================================================
 */

const submitSearch = (form, position) => {
  const locationInput = form.querySelector('#q');
  if (locationInput) {
    locationInput.value = `${position.latitude},${position.longitude}`;
  }
  const queryString = `?q=${position.latitude},${position.longitude}`;
  form.action = `${form.action}${queryString}`;
  form.submit();
};
