export function SearchBoxInit() {
  const searchBox = document.querySelector('.js-search-box');
  const tabButtons = document.querySelectorAll('.js-search-tab-button');
  const nameInput = document.querySelector('.js-name-input');
  const searchForm = document.querySelector('.js-search-form-content');
  const productInput = document.querySelector('.js-product-input');
  const locationInput = document.querySelector('.js-locator-input');

  const productWrapper = document.querySelector('.js-product-input-wrapper');
  const nameWrapper = document.querySelector('.js-name-input-wrapper');
  const locationWrapper = document.querySelector('.js-location-input-wrapper');

  const tabIDsToInputs = {
    "search-name": {"inputs": [nameInput, locationInput], "wrappers": [nameWrapper, locationWrapper]},
    "search-location": {"inputs": [locationInput, productInput], "wrappers": [locationWrapper, productWrapper]},
  }


  const allInputs = [nameInput, locationInput, productInput];
  const allWrappers = [nameWrapper, locationWrapper, productWrapper];

  for (const button of tabButtons) {
    button.addEventListener('click', () => {
      for (const checkButton of tabButtons) {
        checkButton.disabled = false;
      }
      button.disabled = true;


      let active = tabIDsToInputs[button.id];
      if (active) {
        for (const wrapper of active.wrappers) {
          wrapper.classList.add('SearchBox-inputWrapper--visible');
        }

        let inactiveInputs = allInputs.filter(x => !active.inputs.includes(x));
        for (const input of inactiveInputs) {
          input.value = '';
        }

        let inactiveWrappers = allWrappers.filter(x => !active.wrappers.includes(x));
        for (const wrapper of inactiveWrappers) {
          wrapper.classList.remove('SearchBox-inputWrapper--visible');
        }
      }

      scrollTo(button, searchBox);

      /*
       * Only one of the form inputs actually appears/disappears when we
       * switch between tabs, so we use the is-transition class and this setTimeout
       * to simulate the effect of *all* the form inputs and buttons appearing/disappearing.
       * This provides for a smoother transition between the 'Search by Location' view of the
       * form and the 'Search by Name' view
       */
      searchForm.classList.add('is-transition');
      setTimeout(() => {
        searchForm.classList.remove('is-transition');
      }, 250);
    });
  }

  setSearchActiveStyling();
}

const scrollTo = (tabButton, searchBox) => {
  const scrollingParent = searchBox.querySelector('.js-scrollable-container');
  scrollingParent.scrollLeft = tabButton.offsetLeft;
};

const setSearchActiveStyling = () => {
  const searchButton = document.querySelector('#js-form-submit');
  const events = ['touchstart', 'click'];
  for (const eventType of events) {
    searchButton.addEventListener(eventType, () => {
      searchButton.classList.add('active');
      setTimeout(() => {
        searchButton.classList.remove('active')
      }, 300);
    });
  }
};
